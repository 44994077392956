import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

const miod = {
  color: '#ca9502' 
}

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/generalSlides/szkola_animatora_4.png'} alt={'plakat szkoła animatora 4 2019'} style={{ padding: '30px', margin: '5vw auto', width: '100%' }} />
            <div>
              <h2 className="miod"><strong style={miod}>DIECEZJALNA SZKOŁA ANIMATORA</strong> </h2>
              <p><em>SPOTKANIE CZWARTE - 29.02.2020 r. w Domu Miłosierdzia w Otmuchowie</em> </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
